

























import Vue from 'vue';
export default Vue.extend({
  name: 'EditableCell',
  props: ['text'],
  data() {
    return {
      inputValue: '',
      editable: false,
    };
  },
  watch: {
    // text: function (val) {
    //   if (val) {
    //     this.inputValue = val;
    //   }
    // },
    text: {
      handler: function (val) {
        if (val) {
          this.inputValue = val;
        }
      },
      immediate: true,
    },
  },
  methods: {
    handleChange() {
      this.inputValue = (this.inputValue.match(/\d{0,8}(\.\d{0,2}|100)?/) || [
        '',
      ])[0];
    },
    check() {
      this.editable = false;
      this.$emit('standardPriceChange', this.inputValue);
    },
    edit() {
      this.editable = true;
    },
  },
});
